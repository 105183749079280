import React from 'react'
import './Services.scss'
import GreyBlock from '../../components/GreyBlock/GreyBlock'

const Services = () => {
  return (
    <div className='services-page'>
      <div className="section-wrapper">
        <section className="services-banner-section">
          <div className="section-wrapper">
            <div className="title-wrapper">
              <h1 style={{ textAlign: 'center' }} className="hero-title">Nuestros Servicios</h1>
              <p style={{ color: "#2e3035", textAlign: "center" }}>
                Ofrecemos servicios de maquila textil que incluyen confección, producción en serie, diseño y desarrollo de producto(s), personalización de prenda(s), asesoramiento técnico y más servicios que podrás consultar aquí.
              </p>
            </div>
          </div>
        </section>
        <section className='services-wrapper'>
          <div className="details-wrapper">
            <div className="left-side">
              <h1 className='hero-title'>¿Qué producimos?</h1>
              <div className="text-wrapper">
                <ul style={{ color: "#2e3035" }}>
                  <li>Prendas streetwear.</li>
                  <li>Prendas casuales.</li>
                  <li>Prendas deportivas.</li>
                  <li>Merchandising.</li>
                  <li>Uniformes escolares.</li>
                  <li>Uniformes ejecutivos.</li>
                  <li>Uniformes industriales.</li>
                  <li>Uniformes hospitalarios.</li>
                  <li>Accesorios.</li>
                  <li>Empaques.</li>
                  <li>Mochilas.</li>
                  <li>Artículos promocionales.</li>
                </ul>
              </div>
            </div>
            <div className="right-side">
              <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339633/republica-textil/group-61_a3ksjo.webp" alt="" />
            </div>
          </div>
          <div className="details-wrapper">
            <div className="left-side">
              <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339633/republica-textil/group-60_s4n9qi.webp" alt="" />
            </div>
            <div className="right-side">
              <h1 className='hero-title'>Ejemplos de productos</h1>
              <div className="text-wrapper">
                <ul style={{ color: "#2e3035" }}>
                  <li>Playeras.</li>
                  <li>Sudaderas con gorro.</li>
                  <li>Sudaderas sin gorro.</li>
                  <li>Playeras tipo polo.</li>
                  <li>Camisas.</li>
                  <li>Tank tops.</li>
                  <li>Crop tops.</li>
                  <li>Calcetas.</li>
                  <li>Chamarras.</li>
                  <li>Pantalónes.</li>
                  <li>Joggers.</li>
                  <li>Shorts.</li>
                  <li>Trajes de baño.</li>
                  <li>Faldas.</li>
                  <li>Vestidos casuales.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="details-wrapper">
            <div className="left-side">
              <h1 className='hero-title'>Servicios</h1>
              <div className="text-wrapper">
                <ul style={{ color: "#2e3035" }}>
                  <li>Asesoría en creación de marca y producto.</li>
                  <li>Diseño y desarrollo de producto.</li>
                  <li>Propuesta de productos.</li>
                  <li>Diseño gráfico y tipográfico.</li>
                  <li>Patronaje.</li>
                  <li>Fichas técnicas.</li>
                  <li>Muestras físicas.</li>
                  <li>Adquisición de Materia prima e  insumos.</li>
                  <li>Corte.</li>
                  <li>Confección.</li>
                  <li>Logística.</li>
                  <li>Branding.</li>
                  <li>Serigrafía.</li>
                  <li>Bordado.</li>
                  <li>Microbordado.</li>
                  <li>DTF.</li>
                  <li>DTG.</li>
                  <li>Sublimado.</li>
                  <li>Impresión directa.</li>
                  <li>Teñido.</li>
                  <li>Deslavados.</li>
                  <li>Tie Dye.</li>
                  <li>Corte láser.</li>
                  <li>Empacado Unitario.</li>
                  <li>Packaging.</li>
                  <li>Fotografía de producto.</li>
                  <li>Desarrollo de e-commerce.</li>
                  <li>Gestión y desarrollo de redes sociales.</li>
                </ul>
              </div>
            </div>
            <div className="right-side">
              <div className="img-wrapper">
                <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339633/republica-textil/group-59_zflcqx.webp" alt="" />
                <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339633/republica-textil/group-63_bvufom.webp" alt="" />
              </div>
            </div>
          </div>
          <GreyBlock title={'¿No encuentras lo que necesitas?'} />
        </section>
      </div>
    </div>
  )
}

export default Services