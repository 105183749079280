import React from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";


const Navbar = ({toggle}) => {
  return (
    <>
      <div className="navbar-wrapper">
        <nav className="navbar-section">
          <div className="navbar-list">
            <Link to="/servicios">Servicios</Link>
            <Link to="/showroom">Showroom</Link>
            <Link to="/nosotros">Nosotros</Link>
            <Link to="/ayuda">FAQs</Link>
            <Link to="/contacto">Contacto</Link>
          </div>
        </nav>
        {/*<div className="language-items">
            EN <div className="line"></div> ES
  </div>*/}
      </div>
      <div className="burger-wrapper">
        <GiHamburgerMenu onClick={toggle} />
      </div>
    </>
  );
};

export default Navbar;
