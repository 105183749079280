import React from 'react'

const NotFound = () => {
  return (
    <div className='notfound-section'>
        <h3>La página a la que estas intentado acceder no esta disponible</h3>
    </div>
  )
}

export default NotFound