import React, { useEffect, useState } from 'react'
import data from '../../api/data.json'
import Element from '../../components/Navigator/Element'
import { Link } from 'react-router-dom'


const ShowroomIndex = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 524;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    })

    const ShowRoomDesktop = () => {
        return (
            <div className="projects-list">
                {data.map((project) => (
                    <Link to={`/showroom/${project.slug}`}>
                        <Element key={project.slug} url={project.media[0].url} alt={project.title} slug={project.slug} />
                    </Link>
                ))}
            </div>
        )
    }

    const ShowRoomMobile = () => {
        return (
            <div className='projects-mobile-list'>
                {data.map((project) => (
                    <Link to={`/showroom/${project.slug}`}>
                        <Element key={project.slug} url={project.media[0].url} alt={project.title} slug={project.slug} />
                    </Link>
                ))}
            </div>
        )
    }

    return (
        <>
            {width > breakpoint ? <ShowRoomDesktop /> : <ShowRoomMobile />}
        </>
    )
}

export default ShowroomIndex