import React from "react";
import "./HomeContact.scss";
import ContactForm from "../../../components/ContactForm/ContactForm";

const HomeContact = () => {
  const templateID = "template_iimp2un";
  const inputs = [
    {
      id: 1,
      name: "from_name",
      type: "text",
      placeholder: "Nombre completo",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 2,
      name: "customer_email",
      type: "email",
      placeholder: "Correo eléctronico",
      errorMessage: "Este campo debe ser un correo electrónico",
      required: true,
    },
    {
      id: 3,
      name: "customer_phonenumber",
      type: "tel",
      placeholder: "Número de teléfono",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 4,
      name: "customer_company",
      type: "text",
      placeholder: "Empresa",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 5,
      name: "message",
      type: "text",
      placeholder: "Háblanos de tu proyecto",
      errorMessage: "Este campo es requerido",
      required: true,
    },
  ];
  return (
    <section className="quote-section">
      <img
        className="big-absolute-wrapper"
        src="https://res.cloudinary.com/droimgtqi/image/upload/v1678830230/republica-textil/Group_bh09vw.svg"
        alt=""
      />
      <img
        className="sm-absolute-wrapper"
        src="https://res.cloudinary.com/droimgtqi/image/upload/v1678830230/republica-textil/Group-1_aqkdt7.svg"
        alt=""
      />
      <div className="section-wrapper">
        <div className="left-side">
          <h1>Hablemos de tu proyecto</h1>
          <p>
            ¡Estamos emocionados de ayudarte a crear tu propia marca de ropa!
          </p>
          <p>
            Contáctanos para programar una llamada o para recibir una
            cotización.
          </p>
        </div>
        <div className="right-side">
          <ContactForm templateID={templateID} inputs={inputs} />
        </div>
      </div>
    </section>
  );
};

export default HomeContact;
