import React, { useEffect, useState } from 'react'


const Project = ({ title, media }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 524;

  const DesktopList = () => {
    return (
      <div className='projects-list'>
        {media.map((photo) => (
          <img src={photo.url} alt={title} />
        ))}
      </div>
    )
  }

  const MobileList = () => {
    return (
      <div className='projects-mobile-list'>
        {media.map((photo) => (
          <img src={photo.url} alt={title} />
        ))}
      </div>
    )
  }

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  })
  return (
    <>
      {width > breakpoint ? <DesktopList /> : <MobileList />}
    </>
  )
}

export default Project