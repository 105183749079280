import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import ContactInput from "./ContactInput";
import "./ContactForm.scss";

const ContactForm = ({ templateID, inputs }) => {
  const form = useRef();
  const SERVICE_ID = "service_4wtyyaf";
  const TEMPLATE_ID = templateID;
  const USER_ID = "RW3zsNJlyQgqB82lF";

  const [values, setValues] = useState({
    from_name: "",
    customer_email: "",
    customer_phonenumber: "",
    customer_company: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        Swal.fire({
          icon: "success",
          title: "Mensaje enviado",
          text: "Gracias por contáctarnos",
        });
        setValues({
          from_name: "",
          customer_email: "",
          customer_phonenumber: "",
          customer_company: "",
          message: "",
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Oops, algo salío mal",
          text: error.text,
        });
      }
    );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact-form">
      <form ref={form} className="inner-wrapper" onSubmit={handleSubmit}>
        {inputs.map((input) => (
          <ContactInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button type="submit" className="primary-btn">
          <div>Enviar mensaje</div>
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
