import React from "react";
import { motion } from "framer-motion";
import "./Accordion.scss";

const Accordion = ({ faq, expanded, setExpanded }) => {
  const isOpen = faq.id === expanded;
  return (
    <div
      className="accordion"
      onClick={() => setExpanded(isOpen ? false : faq.id)}
    >
      <div className="accordion-header">
        <div className="accordion-icon">
          <motion.span
            animate={{ rotate: isOpen ? 0 : 45, x: 4 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
          <motion.span
            animate={{ rotate: isOpen ? 0 : -45, x: -3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
        </div>
        {faq.question}
      </div>
      <motion.div
        key="content"
        animate={{ height: isOpen ? "100%" : "0" }}
        transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        className="accordion-content"
      >
        <span>{faq.answer}</span>
      </motion.div>
    </div>
  );
};

export default Accordion;
