import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./Header.scss";

const Header = ({ toggle }) => {
  return (
    <div className="header-section">
      <div className="section-wrapper">
        <div className="logo-wrapper">
          <Link className="logo-section" to="/">
            <img src="https://res.cloudinary.com/droimgtqi/image/upload/v1680050411/republica-textil/Group_5_1_ag2mqm.png" />
            República
            <br />
            Textil
          </Link>
        </div>
        <Navbar toggle={toggle} />
      </div>
    </div>
  );
};

export default Header;
