import React from "react";
import "./Sidebar.scss";
import { MdClose } from "react-icons/md";
import { FullMenu } from "./SidebarStyles";
import { Link } from "react-router-dom";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <FullMenu isOpen={isOpen} onClick={toggle}>
      <div className="menu-wrapper">
        <div className="close-button">
          <MdClose onClick={toggle} size={24} />
        </div>
      </div>
      <div className="nav-wrapper">
        <nav className="navbar-section">
          <Link to="/servicios">Servicios</Link>
          <Link to="/showroom">Showroom</Link>
          <Link to="/nosotros">Nosotros</Link>
          <Link to="/ayuda">FAQs</Link>
          <Link to="/contacto">Contacto</Link>
        </nav>
      </div>
    </FullMenu>
  );
};

export default Sidebar;
