import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Showcase from "./pages/Showcase/Showcase";
import Footer from "./components/Footer/Footer";
import WhatsappButton from "./components/WhatsappButton/WhatsappButton";
import Sidebar from "./components/Sidebar/Sidebar";
import Faqs from "./pages/Faqs/Faqs";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  return (
    <div className="App">
      <Header toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut" && location !== displayLocation) {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          <Route index element={<Home />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/showroom/*" element={<Showcase />}/>
          <Route path="/nosotros" element={<About />} />
          <Route path="/ayuda" element={<Faqs />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
      <WhatsappButton />
    </div>
  );
}

export default App;
