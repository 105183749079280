import React from 'react'
import './Contact.scss'
import ContactForm from '../../components/ContactForm/ContactForm'

const Contact = () => {
  const templateID = "template_iimp2un";
  const inputs = [
    {
      id: 1,
      name: "from_name",
      type: "text",
      placeholder: "Nombre completo",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 2,
      name: "customer_email",
      type: "email",
      placeholder: "Correo eléctronico",
      errorMessage: "Este campo debe ser un correo electrónico",
      required: true,
    },
    {
      id: 3,
      name: "customer_phonenumber",
      type: "tel",
      placeholder: "Número de teléfono",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 4,
      name: "customer_company",
      type: "text",
      placeholder: "Empresa",
      errorMessage: "Este campo es requerido",
      required: true,
    },
    {
      id: 5,
      name: "message",
      type: "text",
      placeholder: "Háblanos de tu proyecto",
      errorMessage: "Este campo es requerido",
      required: true,
    },
  ];
  return (
    <div className='contact-page'>
      <div className="section-wrapper">
        <section className="about-banner-section">
          <div className="section-wrapper">
            <div className="title-wrapper">
              <h1 style={{ textAlign: 'center' }} className="hero-title">Contactános</h1>
              <p style={{ color: "#2e3035", textAlign: "center" }}>Con gusto atenderemos todas tus necesidades y preguntas. Comentanos con lo que te podemos apoyar en el formulario de abajo o tambien nos puedes contactar por nuestros otros medios de contacto.</p>
            </div>
          </div>
        </section>
        <section className='contact-form-wrapper'>
          <ContactForm templateID={templateID} inputs={inputs} />
        </section>
      </div>
    </div>
  )
}

export default Contact