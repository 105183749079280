import React from "react";
import "./HomeShowcase.scss";
import { Link } from "react-router-dom";

const HomeShowcase = () => {
  return (
    <section className="showcase-section">
      <div className="section-wrapper">
        <h1 className="main-color">SHOWROOM</h1>
        <div className="img-wrapper">
          <Link to='/showroom/casual-clothes'>
            <img
              className="row-showcase mr-48"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1681155565/republica-textil/Group-46_uv4izq.webp"
              alt=""
            />
          </Link>
          <Link to='/showroom/sport-clothes'>
            <img
              className="row-showcase"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1681155567/republica-textil/Group-48_1_ajxnfg.webp"
              alt=""
            />
          </Link>
          <Link to='/showroom/merchandising'>
            <img
              className="row-showcase"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1681155568/republica-textil/Group-49_1_fkbzgb.webp"
              alt=""
            />
          </Link>
          <Link to='/showroom/corporate-wear'>
            <img
              className="row-showcase"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1681155562/republica-textil/Group-50_bjqoc1.webp"
              alt=""
            /></Link>
          <Link to='/showroom/streetwear'>
            <img
              className="row-showcase"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1681155556/republica-textil/Group-51_v9qyah.webp"
              alt=""
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeShowcase;
