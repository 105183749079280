import React from "react";
import "./Home.scss";
import HomeHero from "./HomeHero/HomeHero";
import HomeAbout from "./HomeAbout/HomeAbout";
import HomeShowcase from "./HomeShowcase/HomeShowcase";
import HomeContact from "./HomeContact/HomeContact";

const Home = () => {
  return (
    <div className="home-landing">
      <HomeHero />
      <HomeAbout />
      <HomeShowcase />
      <HomeContact />
    </div>
  );
};

export default Home;
