import styled from "styled-components";

export const FullMenu = styled.div`
    position: fixed;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 28px 45px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

    @media screen and (max-width: 1024px) {
        padding: 52px 36px;
        transition: all ease-in-out 0.2s;
      }

    @media screen and (min-width:1025px){
    display: none;
    }

    
    
`