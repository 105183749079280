import React from 'react';
import Navigator from '../../components/Navigator/Navigator';
import Project from './Project';
import { Route, Routes, Outlet } from 'react-router-dom';
import data from '../../api/data.json'
import './Showcase.scss';
import ShowroomIndex from '.';
import NotFound from '../NotFound/NotFound';

const Showcase = () => {

  return (
    <div className="showcase-page">
      <div className='section-wrapper'>
        <div className='showcase-list'>
          <Navigator />
          <Routes>
            <Route path='/' element={<ShowroomIndex />} />
            {data.map((project) => (
              <Route path={`/${project.slug}`} element={<Project title={project.title} media={project.media} />} />
            ))}
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Showcase