import React, { useState, useEffect } from "react";
import Accordion from "../../components/Accordion/Accordion";
import GreyBlock from "../../components/GreyBlock/GreyBlock";
import "./Faqs.scss";

const faqs = [
  {
    id: 0,
    question: "¿Pueden replicar los productos que ya manejo actualmente?",
    answer:
      "Sí, podemos trabajar con cualquier producto que necesites, ya sea que lo tengas en físico o en patrón.",
  },
  {
    id: 1,
    question: "¿Qué necesito para cotizar?",
    answer:
      "Fotografía de producto, descripción técnica del mismo, imagen de los diseños gráficos a aplicar y sus medidas, cantidad de prendas necesarias",
  },
  {
    id: 2,
    question:
      "¿Puedo trabajar con ustedes si vivo en otro estado de la república o en otro país?",
    answer:
      "Claro, la distancia no es inconveniente. Siempre te mantendremos informado de todos los avances en tus proyecto y te enviaremos hasta tu domicilio las muestras físicas que necesites para tomar las decisiones.",
  },
  {
    id: 3,
    question:
      "¿Puedo visitarlos en algún lugar para ver en persona los productos y conocer más sobre su servicio?",
    answer:
      "¡Sí! Siempre serás bienvenido en nuestro Showroom en Guadalajara Jalisco para conocer y seleccionar la materia prima y los acabados que necesitas para tu proyecto.",
  },
  {
    id: 4,
    question:
      "¿Si aún no estoy muy seguro de lo que quiero, podemos empezar a trabajar?",
    answer:
      "Sí, con nuestro servicio de asesoría para marcas y productos podemos ayudarte a aterrizar tus ideas y tu proyecto para que lo veas por fin definido en algo real y tangible.",
  },
  {
    id: 5,
    question: "¿Cúal es su mínimo de producción?",
    answer: "En la mayoría de los productos es de 60 piezas por modelo.",
  },
  {
    id: 6,
    question: "¿Cúal es el tiempo de entrega estimado?",
    answer:
      "Todas los productos son hechos especialmente uno por uno para ti, por lo que deberás de consultar el tiempo de entrega al momento de realizar tu cotización, agradecemos tu paciencia y confianza en nuestro equipo.",
  },
  {
    id: 7,
    question: "¿Puedo pedir una distribución de tallas específica?",
    answer:
      "Sí, puedes solicitar tu corrida de tallas siempre y cuando sean multiplos entre sí.",
  },
  {
    id: 8,
    question: "¿Necesito tener los derechos de autor para hacer una impresión?",
    answer:
      "Sí, de acuerdo a las leyes actuales de nuestro país no se realizan impresiones que tengan registro de derechos de autor",
  },
];

const Faqs = () => {
  const [expanded, setExpanded] = useState(0);
  return (
    <section className="faqs-section">
      <div className="section-wrapper">
        <div className="faqs-header">
          <h1 style={{ textAlign: "center" }} className="hero-title">
            ¿Tienes preguntas? <br /> Contamos con las respuestas
          </h1>
          <p style={{ color: "#2e3035", textAlign: "center" }}>
            Aquí encontrarás todo lo que necesitas saber antes de iniciar tu
            proyecto con nosotros. <br /> ¿No encuentras la respuesta a tu
            pregunta?{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://wa.me/+5213330595038?text=Hola,%20me%20gustar%C3%ADa%20recibir%20m%C3%A1s%20informaci%C3%B3n%20para%20mi%20proyecto"
              target={"_blank"}
            >
              <b>Mándanos un mensaje por WhatsApp.</b>
            </a>
          </p>
        </div>
        <div className="faqs-container">
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              faq={faq}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ))}
        </div>
        <GreyBlock title={'¿Todavía tienes preguntas?'} />
      </div>
    </section>
  );
};

export default Faqs;
