import React from "react";
import "./AboutBanner.scss";

const AboutBanner = () => {
  return (
    <section className="about-banner-section">
      <div className="section-wrapper">
        <div className="title-wrapper">
          <h1 style={{textAlign:'center'}} className="hero-title">Acerca de nosotros</h1>
          <p style={{ color: "#2e3035", textAlign: "center" }}>Aquí sabras más acerca de quienes somos nosotros. Como nos representamos como empresa, <br/>  nuestros valores y nuestra filosofía.</p>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
