import React, { useState, useEffect } from "react";
import "./WhatsappButton.scss";

const WhatsappButton = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <a href="https://wa.me/+5213330595038?text=Hola,%20me%20gustar%C3%ADa%20recibir%20m%C3%A1s%20informaci%C3%B3n%20para%20mi%20proyecto" className="whats-button" target={"_blank"}>
      <div className="inner-wrapper">
        <img
          width={width > breakpoint ? 100 : 28}
          src={width > breakpoint ? "https://res.cloudinary.com/droimgtqi/image/upload/v1677784437/Digital_Inline_White_naym4y.svg" : "https://res.cloudinary.com/droimgtqi/image/upload/v1674592535/Digital_Glyph_White_fijvrl.svg"}
          alt=""
        />
      </div>
    </a>
  );
};

export default WhatsappButton;
