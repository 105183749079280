import React, { useState, useEffect } from 'react'
import data from '../../api/data.json'
import './Navigator.scss'
import { Link } from 'react-router-dom'

export default function Navigator() {
    const [width, setWidth] = useState(window.innerWidth)
    const breakpoint = 524;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
      })

    const DesktopNav = () => {
        return (
            <ul className='projects-nav'>
                {data.map((project) => (
                    <li key={project.slug}>
                        <Link to={project.slug}>{project.title}</Link>
                    </li>
                ))}
            </ul>
        )
    }

    const MobileNav = () => {
        return (
            <ul className='mobile-projects'>
            {data.map((project) => (
                <li key={project.slug}>
                    <Link to={project.slug}>{project.title}</Link>
                </li>
            ))}
            </ul>
        )
    }

    return (
        <>
        {width > breakpoint ? <DesktopNav /> : <MobileNav />}
        </>
    )
}
