import React from 'react'
import './GreyBlock.scss'

const GreyBlock = ({title, text}) => {
    return (
        <div className="cta-block">
            <h2 style={{ color: "#2e3035" }}>{title}</h2>
            <p className="hero-sm" style={{ textAlign: "center", color: "#2e3035" }}>
                Envíanos un mensaje a{" "}
                <a
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                    href="mailto:contacto@republicatextil.com"
                >
                    contacto@republicatextil.com
                </a>{" "}
                ó a nuestro{" "}
                <a
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                    href="https://wa.me/+5213330595038?text=Hola,%20me%20gustar%C3%ADa%20recibir%20m%C3%A1s%20informaci%C3%B3n%20para%20mi%20proyecto"
                    target={"_blank"}
                >
                    WhatsApp
                </a>&nbsp;y con gusto atenderemos tus necesidades.
            </p>
        </div>
    )
}

export default GreyBlock