import React, { useState, useEffect } from "react";
import "./HomeHero.scss";

const HomeHero = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <section className="hero-section">
      <div className="section-wrapper">
        <div className="top-banner">
          <div className="left-side">
            <h1 className="hero-title mv-4">
              Gestión de proyectos textiles para empresas y marcas.
            </h1>
          </div>
          <div className="right-side">
            <label className="hero-sm">
              Creamos tu marca de ropa desde cero. <br /> Te acompañamos en cada
              paso del proceso, desde el diseño hasta la producción y venta.
            </label>
          </div>
        </div>
        <div className="customers-wrapper">
          <div className="inner-wrapper">
            <img
              className="customer-img"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1679700971/republica-textil/Group_14_mpwwu4.png"
            />
            <img
              className="customer-img"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1679700972/republica-textil/Group_15_nhpdtj.png"
            />
            <img
              className="customer-img"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1679700973/republica-textil/Group_26_1_mxtlck.png"
            />
            <img
              className="customer-img"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1679700973/republica-textil/Group_27_tzspoe.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
