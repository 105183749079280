import React from 'react'
import './AboutStory.scss'

const AboutStory = () => {
    return (
        <section className='about-wrapper'>
            <div className="story-wrapper">
                <div className="left-side">
                    <h1 className='hero-title'>¿Quienes somos?</h1>
                    <div className="text-wrapper">
                        <p style={{ color: "#2e3035" }}>República Textil es una empresa dedicada a la gestión de proyectos textiles. <br />Resolvemos todas y cada una de las etapas creativas, productivas y de venta por las que pasa un producto.</p>
                        <p style={{ color: "#2e3035" }}>Con nosotros puedes personalizar de 0% a 100% todos y cada uno de los detalles que necesites.</p>
                    </div>
                </div>
                <div className="right-side">
                    <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339844/republica-textil/group-30-1-bpntlz_pymdym.webp" alt="" />
                </div>
            </div>
            <div className="story-wrapper">
                <div className="left-side">
                    <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339844/republica-textil/group-33-j8q52v_zzmtma.webp" alt="" />
                </div>
                <div className="right-side">
                    <h1 className='hero-title'>Misión</h1>
                    <div className="text-wrapper">
                        <ul style={{ color: "#2e3035" }}>
                            <li>Inspirar tranquilidad y confianza.</li>
                            <li>Crear valor y hacer la diferencia.</li>
                            <li>Fomentar la innovación, el consumo local y las buenas prácticas.</li>
                        </ul>
                    </div>
                    <h1 className='hero-title'>Visión</h1>
                    <div className="text-wrapper">
                        <ul style={{ color: "#2e3035" }}>
                            <li>Ser una empresa donde se motive a ser mejores siempre.</li>
                            <li>Ofrecer un catálogo de productos y materiales de alta calidad que cubra las necesidades del cliente.</li>
                            <li>Ser responsables con nuestra comunidad y medio ambiente.</li>
                            <li>Ser una empresa altamente efectiva y organizada.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="story-wrapper">
                <div className="left-side">
                    <h1 className='hero-title'>Valores</h1>
                    <div className="text-wrapper">
                        <ul style={{ color: "#2e3035" }}>
                            <li><b>Colaboración:</b> El trabajo en equipo es la mejor manera de hacer las cosas bien.</li>
                            <li><b>Integridad:</b> Ser congruentes, reales y respetables.</li>
                            <li><b>Igualdad:</b> Posibilidades para todos.</li>
                            <li><b>Pasión:</b> Entregarnos totalmente de corazón a lo que hacemos.</li>
                            <li><b>Calidad:</b> Lo hacemos bien, lo hacemos rápido, lo hacemos durable.</li>
                        </ul>
                    </div>
                </div>
                <div className="right-side">
                    <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339845/republica-textil/group-31-4-jnm71c_rcgesi.webp" alt="" />
                </div>
            </div>
            <div className="story-wrapper">
                <div className="left-side">
                    <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339844/republica-textil/group-29-redud5_mcawin.webp" alt="" />
                </div>
                <div className="right-side">
                    <h1 className='hero-title'>Maquila Justa</h1>
                    <div className="text-wrapper">
                    <p style={{ color: "#2e3035" }}>Operamos bajo un modelo de “Maquila Justa” en el que cada eslabón del proceso recibe lo justo por su trabajo, esto de forma ética, responsable y congruente.</p>
                    </div>
                </div>
            </div>
            <div className="story-wrapper">
                <div className="left-side">
                <h1 style={{maxWidth:"500px"}} className='hero-title'>Compromiso medioambiental y social</h1>
                    <div className="text-wrapper">
                    <p style={{ color: "#2e3035" }}>La industria del vestir es la responsable de una gran parte de la contaminación y devastación de nuestro planeta, por lo que estamos ampliamente comprometidos a reducir nuestra huella en el. <br /><br />Esto lo hacemos siempre promoviendo los materiales de origen natural, los reciclados y los reutilizables. <br /><br /> La calidad en los productos que ofrecemos hace frente al modelo de consumo del “Fast fashion” donde tratamos de dar la mayor vida posible a las prendas que fabricamos.</p>
                    </div>
                </div>
                <div className="right-side">
                    <img className='img-about' src="https://res.cloudinary.com/droimgtqi/image/upload/v1681339844/republica-textil/group-28-cudrld_ri5sic.webp" alt="" />
                </div>
            </div>
        </section>
    )
}

export default AboutStory