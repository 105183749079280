import React from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import AboutStory from './AboutStory/AboutStory'
import './About.scss'

const About = () => {
  return (
    <main className='about-page'>
      <div className='section-wrapper'>
        <AboutBanner />
        <AboutStory />
      </div>
    </main>
  )
}

export default About