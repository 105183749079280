import React from "react";
import InfiniteLooper from "../../../components/InfiniteLooper/InfiniteLooper";
import { Link } from "react-router-dom";
import "./HomeAbout.scss";

const HomeAbout = () => {
  return (
    <section className="about-section">
      <div className="top-content">
        <InfiniteLooper speed="34">
          <p>Propuesta de diseño</p>
          <p>Propuesta de producto</p>
          <p>Desarrollo de fichas tecnicas de aplicación</p>
          <p>Aplicaciones</p>
          <p>Packaging</p>
        </InfiniteLooper>
      </div>
      <div className="mid-content">
        <div className="section-wrapper">
          <h1 style={{ color: "white", fontSize: "32px" }}>NOSOTROS</h1>
          <p>
            Ofrecemos una variedad de servicios para ayudarte a crear tu propia
            marca de ropa.
          </p>
          <p>
            Desde el diseño hasta la producción, estamos a tu lado en cada paso
            del proceso. Explora nuestros servicios y elige el que mejor se
            adapte a tus necesidades.
          </p>
          <Link to="/servicios" className="mt-60 secondary-button">
            <div>Ver todos los servicios</div>
            </Link>
        </div>
      </div>
      <div className="bottom-content">
        <InfiniteLooper speed="34" direction="right">
          <p>Playeras</p>
          <p>Sueteres</p>
          <p>Sudaderas</p>
          <p>Bomber Jackets</p>
          <p>Leggings</p>
          <p>Shorts</p>
          <p>Boxers</p>
          <p>Ponchos</p>
          <p>Crewnecks</p>
          <p>Calcetas</p>
        </InfiniteLooper>
      </div>
    </section>
  );
};

export default HomeAbout;
